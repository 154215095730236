<template>
  <AppHeaderDropdown right no-caret>
    <template slot="header">
      <i class="icon-bell"></i>
      <b-badge pill variant="danger" v-if="alertsLength <= 30">{{ alertsLength }}</b-badge>
      <b-badge pill variant="danger" v-else>30+</b-badge>
    </template>
    <template slot="dropdown">
      <div style="position:relative; height:400px; overflow-y:scroll;">
        <b-dropdown-header tag="div" class="text-center">
          <strong>alert</strong>
        </b-dropdown-header>
        <b-dropdown-item
          boundary="viewport"
          v-for="item in alerts.edges"
          :key="item.node.id"
          :value="item.node"
        >
          <div>
            <i class="fa fa-bell-o" />
            {{ item.node.content }}
          </div>
        </b-dropdown-item>
      </div>
    </template>
  </AppHeaderDropdown>
</template>

<script>
import { HeaderDropdown as AppHeaderDropdown } from "@coreui/vue";
import { ALERT_QUERY } from "../../components/alert-query";
import gql from "graphql-tag";
export default {
  name: "AlertDropdown",
  components: {
    AppHeaderDropdown
  },
  data() {
    return {
      alerts: {
        edges:[]
      }
    };
  },
  apollo: {
    alerts: {
      query: ALERT_QUERY,
      result({data}) {

      }
    }
  },
  computed: {
    alertsLength: function() {
      return this.alerts.edges.length;
    }
  }
};
</script>
