<template>
  <div class="app" v-show="islogin">
    <AppHeader fixed>
      <SidebarToggler class="d-lg-none" display="md" mobile />
      <SidebarToggler class="d-md-down-none" display="lg" />
      <b-navbar-nav class="ml-auto">
        <b-nav-item v-on:click="onLogout">ログアウト</b-nav-item>
        <!-- <p class="header-farmer-select">
          <i class="fa fa-building fa-lg"></i>
          {{ currentFarmer.name }}
        </p>
        <p class="header-farmer-select">
          <router-link to="/setfarmer">
            農家を変更してログイン
          </router-link>
        </p>-->
        <!--<alert-dropdown />-->
      </b-navbar-nav>
    </AppHeader>
    <div class="app-body">
      <AppSidebar fixed>
        <SidebarHeader />
        <SidebarForm />
        <SidebarNav :navItems="nav"></SidebarNav>
        <SidebarFooter />
        <SidebarMinimizer />
      </AppSidebar>
      <main class="main">
        <Breadcrumb :list="list" />
        <div class="container-fluid">
          <router-view></router-view>
        </div>
      </main>
      <AppAside fixed></AppAside>
    </div>
    <!--footer-->
    <!--<TheFooter>
      <div>
        <a href="https://coreui.io">CoreUI</a>
        <span class="ml-1">&copy; 2018 creativeLabs.</span>
      </div>
      <div class="ml-auto">
        <span class="mr-1">Powered by</span>
        <a href="https://coreui.io">CoreUI for Vue</a>
      </div>
    </TheFooter>-->
  </div>
</template>

<script>
import {
  Header as AppHeader,
  SidebarToggler,
  Sidebar as AppSidebar,
  SidebarFooter,
  SidebarForm,
  SidebarHeader,
  SidebarMinimizer,
  SidebarNav,
  Aside as AppAside,
  AsideToggler,
  Footer as TheFooter,
  Breadcrumb,
} from "@coreui/vue";
import DefaultAside from "./DefaultAside";
import AlertDropdown from "./AlertDropdown";
import DefaultHeaderDropdownAccnt from "./DefaultHeaderDropdownAccnt";
import { CALL_ME } from "../../components/login-auth";
import { FARMER_ID } from "../../components/auth-settings";
import SetFarmer from "../pages/SetFarmer";
import store from "../../components/store";

const CREDENTIAL_ERROR = "この操作を実行する権限がありません";
const DEFAULT_INDEX = 0;

export default {
  name: "DefaultContainer",
  components: {
    AsideToggler,
    AppHeader,
    AppSidebar,
    AppAside,
    TheFooter,
    Breadcrumb,
    DefaultAside,
    DefaultHeaderDropdownAccnt,
    AlertDropdown,
    SidebarForm,
    SidebarFooter,
    SidebarToggler,
    SidebarHeader,
    SidebarNav,
    SidebarMinimizer,
  },
  data() {
    return {
      nav: [
        { name: "最新画像一覧", url: "/camerapicturespage" },
        { name: "カメラマスタ管理", url: "/camerapage" },
        { name: "畑マスタ管理", url: "/fieldpage" },
      ],
      islogin: false,
      pages: [],
      authGroupsOperators: [],
      // currentFarmer: null
    };
  },
  beforeCreate() {
    this.$apollo
      .query({
        query: CALL_ME,
      })
      .then((result) => {
        this.islogin = true;
        const me = result.data.me;
        this.authGroupsOperators = me.authgroupsoperatorsSet.edges;
        this.getAuth();
      })
      .catch((response) => {
        if (response.message.indexOf(CREDENTIAL_ERROR) !== -1) {
          this.islogin = false;
          this.$router.push({ path: "/login" });
        }
      });
  },
  computed: {
    name() {
      return this.$route.name;
    },
    list() {
      return this.$route.matched.filter(
        (route) => route.name || route.meta.label
      );
    },
  },
  methods: {
    getAuth() {
      this.authGroupsOperators.forEach((element) => {
        this.setAuthPage(
          element.node.authGroup.authgroupspagepermissionsSet.edges
        );
      });

      // Farmerが複数存在する場合の処理
      // if (localStorage.getItem(FARMER_ID)) {
      //   const farmerId = localStorage.getItem(FARMER_ID);

      //   this.authGroupsOperators.forEach(element => {
      //     if(farmerId == element.node.farmer.id){
      //       this.currentFarmer = element.node.farmer;
      //       this.setAuthPage(element.node.authGroup);
      //     }
      //   });
      // }else{
      //   // ファーマー選択画面に遷移
      //   this.$router.push({ path: "/setFarmer" });
      // }
    },
    // 閲覧できるページを設定
    setAuthPage(pagePermissions) {
      this.pages = [];
      store.urls = [];
      pagePermissions.forEach((element) => {
        this.pages.push(element.node.pagePermission);
        store.urls.push(element.node.pagePermission.url);
      });
    },
    onLogout() {
      localStorage.clear();
      this.$router.push({ path: "/login" });
    },
  },
};
</script>
<style>
.header-farmer-select {
  margin: 10px;
}
</style>
